import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import EventItem from "../components/eventItem";
import Layout from "../components/layout";
import { Nav } from "react-bootstrap";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safeHtmlParser";
import uuid4 from "uuid4";

const WhatWeDoPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				pageData: wpPage(slug: { eq: "your-journey-with-us" }) {
					seoFields {
						title
						metaDescription
						opengraphTitle
						opengraphDescription
						localBusinessSchema
						image {
							node {
								sourceUrl
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
										original {
											width
											height
										}
									}
								}
							}
						}
					}
                    journeyWithUsFieldGroups {
                        contentSection {
                          description
                          heading
                        }
                    }
				}
			}
		`
	);

	const {
		pageData: {
			seoFields,
			journeyWithUsFieldGroups: { contentSection },
		},
	} = data;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "What we do",
				item: {
					url: `${siteUrl}/your-journey-with-us`,
					id: `${siteUrl}/your-journey-with-us`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/your-journey-with-us`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: "What we do at Holding Space",
						},
					],
				}}
			/>

			{contentSection &&
				!checkPropertiesForNull(contentSection, ["heading"]) && (
					<section id="intro" className="py-5">
						<Container>
							<Row>
								<Col>
									<h1 className="text-primary">{contentSection?.heading}</h1>
								</Col>
							</Row>
							<Row>
								<Col>
									<SafeHtmlParser htmlContent={contentSection?.description} />
								</Col>
							</Row>
						
						</Container>
					</section>
				)}

		</Layout>
	);
};
export default WhatWeDoPage;
