import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { SafeHtmlParser } from "../components/safeHtmlParser";

const ContactUsPage = ({ data }) => {
  const {
    pageData: {
      seoFields,
      contactPageField: { contactIntoSection, buttonLabel },
    },
  } = data;

  const { siteUrl } = data.site.siteMetadata;

  return (
    <Layout>
      <Helmet>
        <script
          src="https://www.google.com/recaptcha/api.js"
          async
          defer
        ></script>
      </Helmet>
      <GatsbySeo
        title={seoFields?.title}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/contact-us`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.sourceUrl}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: "Contact Holding Space",
            },
          ],
        }}
      />

      <section className="py-5">
        <Container>
          <Row>
            <Col>
              <h1 className="text-primary">
                {contactIntoSection?.heading || "Contact Us"}
              </h1>
              <SafeHtmlParser htmlContent={contactIntoSection?.description} />
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <form action="https://formspree.io/f/mwkavazn" method="POST">
                <div className="form-group">
                  <input
                    className="form-control bg-light border-0 py-5"
                    placeholder="Name of Parent(s) / Guardian"
                    type="text"
                    name="parent_name"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control bg-light border-0 py-3"
                    rows="3"
                    name="parent_address"
                    placeholder="Address"
                  ></textarea>
                </div>
                <div className="form-group">
                  <input
                    className="form-control bg-light border-0 py-5"
                    placeholder="Telephone Number (Parent/Guardian)"
                    type="text"
                    name="parent_phone"
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control bg-light border-0 py-5"
                    placeholder="Email Address (Parent/Guardian)"
                    type="email"
                    name="parent_email"
                  />
                </div>
                <div className="form-group">
                  <label className="d-block form-label">Add to Mailing List:</label>
                  <div>
                    <label className="mr-3">
                      <input
                        type="radio"
                        name="add_to_mailing_list"
                        value="yes"
                      />{" "}
                      Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="add_to_mailing_list"
                        value="no"
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    className="form-control bg-light border-0 py-5"
                    placeholder="Relationship to Child(ren)"
                    type="text"
                    name="relationship"
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control bg-light border-0 py-5"
                    placeholder="Name of Child(ren)"
                    type="text"
                    name="children_name"
                  />
                </div>
                <div className="form-group">
                  <label className="form-label">Gender:</label>
				  <div>
					<label className="mr-3">
					<input type="radio" name="gender" value="male" /> Male
					</label>
					<label className="mr-3">
					<input type="radio" name="gender" value="female" />{" "}
					Female
					</label>
					<label className="mr-3">
					<input type="radio" name="gender" value="non_binary" />{" "}
					Non-Binary
					</label>
					<label>
					<input
						type="radio"
						name="gender"
						value="prefer_not_to_say"
					/>{" "}
					Prefer not to say
					</label>
				</div>
                </div>
                <div className="form-group">
                  <input
                    className="form-control bg-light border-0 py-5"
                    placeholder="Date of Birth"
                    type="date"
                    name="dob"
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control bg-light border-0 py-5"
                    placeholder="Name of School/Alternative Provision"
                    type="text"
                    name="school_name"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control bg-light border-0 py-3"
                    rows="3"
                    name="gp_details"
                    placeholder="Name and Address of GP"
                  ></textarea>
                </div>
                <div className="form-group">
                  <label className="d-block form-label">
                    Permission to share information with Holding Space?:
                  </label>
                  <div>
                    <label className="mr-3">
                      <input
                        type="radio"
                        name="permission"
                        value="yes_written"
                      />{" "}
                      Yes (Written)
                    </label>
                    <label className="mr-3">
                      <input
                        type="radio"
                        name="permission"
                        value="yes_verbal"
                      />{" "}
                      Yes (Verbal)
                    </label>
                    <label>
                      <input type="radio" name="permission" value="no" /> No
                    </label>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control bg-light border-0 py-3"
                    rows="3"
                    name="referral_reason"
                    placeholder="Reason for Referral"
                  ></textarea>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control bg-light border-0 py-3"
                    rows="3"
                    name="other_support"
                    placeholder="Other Support Services Being Received? Please give details and names/contact details (CAMHs/ Social worker etc)"
                  ></textarea>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control bg-light border-0 py-3"
                    rows="3"
                    name="sen_diagnosis"
                    placeholder="Does the Child(ren) have a SEN Diagnosis? (Please explain)"
                  ></textarea>
                </div>

                <div
                  className="g-recaptcha my-3"
                  data-sitekey="6Ledw8caAAAAAJhWQ_uchVO4C5IFwpJcmFE6t6iT"
                ></div>
                <button
                  className="btn btn-outline-primary mt-3"
                  id="contact-send-btn"
                  type="submit"
                >
                  {buttonLabel ?? "Send Message"}
                </button>
              </form>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
    socialImage: file(relativePath: { eq: "hero-home-comp.jpg" }) {
      publicURL
    }
    pageData: wpPage(slug: { eq: "contact-us" }) {
      seoFields {
        title
        metaDescription
        opengraphTitle
        opengraphDescription
        localBusinessSchema
        image {
          node {
            sourceUrl
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
      }
      contactPageField {
        buttonLabel
        contactIntoSection {
          heading
          description
        }
      }
    }
  }
`;

export default ContactUsPage;
