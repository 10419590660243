import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { SafeHtmlParser } from "../components/safeHtmlParser";

const ReferralPage = ({ data }) => {
	const {
		pageData: {
			seoFields,
			referralPageField: { referralIntoSection },
		},
	} = data;

	const { siteUrl } = data.site.siteMetadata;

	return (
		<Layout>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/referrel`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: "Contact Holding Space",
						},
					],
				}}
			/>

			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h1 className="text-primary">
								{referralIntoSection?.heading || "Contact Us"}
							</h1>
							{referralIntoSection?.description && (
								<SafeHtmlParser
									htmlContent={referralIntoSection?.description}
								/>
							)}
						</Col>
					</Row>
					<Row className="mt-5">
						<Col>
							<form action="https://formspree.io/f/mwkavazn" method="POST">
								{/* Parent/Guardian Information */}
								<div className="form-group">
									<input
										className="form-control bg-light border-0 py-5"
										type="text"
										name="parent_name"
										placeholder="Name of Parent(s) / Guardian"
									/>
								</div>
								<div className="form-group">
									<textarea
										className="form-control bg-light border-0 py-3"
										name="address"
										placeholder="Address"
									></textarea>
								</div>
								<div className="form-group">
									<input
										className="form-control bg-light border-0 py-5"
										type="text"
										name="telephone"
										placeholder="Telephone Number (Parent/Guardian)"
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control bg-light border-0 py-5"
										type="email"
										name="email"
										placeholder="Email Address (Parent/Guardian)"
									/>
								</div>
								<div className="form-group">
									<div className="d-flex justify-content-between">
										<span className="mr-2 form-label">
											Add to mailing list:{" "}
										</span>
										<div>
											<label className="mr-3">
												<input type="radio" name="mailing_list" value="yes" />{" "}
												YES
											</label>
											<label>
												<input type="radio" name="mailing_list" value="no" /> NO
											</label>
										</div>
									</div>
								</div>
								<div className="form-group">
									<input
										className="form-control bg-light border-0 py-5"
										type="text"
										name="relationship_to_children"
										placeholder="Relationship to Child(ren)"
									/>
								</div>

								{/* Child Information */}
								<div className="form-group">
									<textarea
										className="form-control bg-light border-0 py-3"
										name="children_names"
										placeholder="Name of Child(ren)"
									></textarea>
								</div>
								<div className="form-group">
									<div className="d-flex justify-content-between">
										<span className="mr-2 form-label">Gender: </span>
										<div>
											<label className="mr-3">
												<input type="radio" name="gender" value="male" /> Male
											</label>
											<label className="mr-3">
												<input type="radio" name="gender" value="female" />{" "}
												Female
											</label>
											<label className="mr-3">
												<input type="radio" name="gender" value="non_binary" />{" "}
												Non-Binary
											</label>
											<label>
												<input
													type="radio"
													name="gender"
													value="prefer_not_to_say"
												/>{" "}
												Prefer not to say
											</label>
										</div>
									</div>
								</div>
								<div className="form-group">
									<input
										className="form-control bg-light border-0 py-5"
										type="date"
										name="dob"
										placeholder="Date of Birth"
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control bg-light border-0 py-5"
										type="text"
										name="school_name"
										placeholder="Name of School/Alternative Provision"
									/>
								</div>
								<div className="form-group">
									<textarea
										className="form-control bg-light border-0 py-3"
										name="gp_details"
										placeholder="Name and Address of GP"
									></textarea>
								</div>
								<div className="form-group">
									<div className="d-flex justify-content-between">
										<label className="mr-2 form-label">
											Permission to share information with Holding Space?:
										</label>
										<div>
											<label className="mr-3">
												<input
													type="radio"
													name="permission_to_share"
													value="yes_written"
												/>{" "}
												YES (Written)
											</label>
											<label className="mr-3">
												<input
													type="radio"
													name="permission_to_share"
													value="yes_verbal"
												/>{" "}
												YES (Verbal)
											</label>
											<label>
												<input
													type="radio"
													name="permission_to_share"
													value="no"
												/>{" "}
												NO
											</label>
										</div>
									</div>
								</div>

								{/* Referral Information */}
								<div className="form-group">
									<textarea
										className="form-control bg-light border-0 py-3"
										name="referral_reason"
										placeholder="Reason for Referral"
									></textarea>
								</div>
								<div className="form-group">
									<textarea
										className="form-control bg-light border-0 py-3"
										name="support_services"
										placeholder="Other Support Services (e.g., CAMHs, Social Worker)"
									></textarea>
								</div>
								<div className="form-group">
									<textarea
										className="form-control bg-light border-0 py-3"
										name="sen_diagnosis"
										placeholder="Does the child(ren) have a SEN diagnosis? (please explain)"
									></textarea>
								</div>

								{/* Referrer Information */}
								<h2>Referrer Information</h2>
								<div className="form-group">
									<input
										className="form-control bg-light border-0 py-5"
										type="text"
										name="referrer_name"
										placeholder="Name of Referrer"
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control bg-light border-0 py-5"
										type="text"
										name="referrer_relationship"
										placeholder="Relationship"
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control bg-light border-0 py-5"
										type="text"
										name="organisation"
										placeholder="Organisation/School"
									/>
								</div>
								<div className="form-group">
									<textarea
										className="form-control bg-light border-0 py-3"
										name="referrer_address"
										placeholder="Address"
									></textarea>
								</div>
								<div className="form-group">
									<input
										className="form-control bg-light border-0 py-5"
										type="text"
										name="referrer_telephone"
										placeholder="Telephone Number"
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control bg-light border-0 py-5"
										type="email"
										name="referrer_email"
										placeholder="Email Address"
									/>
								</div>
								<div className="form-group">
									<input
										className="form-control bg-light border-0 py-5"
										type="date"
										name="referral_date"
										placeholder="Date of Referral"
									/>
								</div>

								{/* <div className="form-group">
                  <p className="mb-2">
                    Referred For (please select all that apply):
                  </p>
                  <div className="form-group-options">
                    <label className="d-block">
                      <input
                        type="checkbox"
                        name="referred_for"
                        value="counselling_yp"
                      />{" "}
                      Counselling - YP
                    </label>
                    <label className="d-block">
                      <input
                        type="checkbox"
                        name="referred_for"
                        value="counselling_parent"
                      />{" "}
                      Counselling - Parent
                    </label>
                    <label className="d-block">
                      <input
                        type="checkbox"
                        name="referred_for"
                        value="telephone_support"
                      />{" "}
                      Telephone Support
                    </label>
                    <label className="d-block">
                      <input
                        type="checkbox"
                        name="referred_for"
                        value="other_signposting"
                      />{" "}
                      Other Signposting
                    </label>
                  </div>
                </div> */}
								{/* <textarea
                  className="form-control bg-light border-0 py-3"
                  name="signposting_organisation"
                  placeholder="If other signposting, please give the name of the organisation"
                ></textarea> */}
								<div className="form-note">
									<p>
										<strong>Data protection & Privacy:</strong> Holding Space
										will only use this data as part of the referral process and
										details will only be passed to members of the Holding Space
										team. We will maintain your privacy and confidentiality in
										line with our Privacy Policy. A copy is available on our
										website or on request{" "}
									</p>
									<p>
										<strong>Safeguarding: </strong>It is our duty of care to
										report any concerns we have in terms of safe guarding the
										welfare of any person in the family. We will make you aware
										of this before we refer to the appropriate organisation. A
										copy of our safe guarding policy is available upon request.{" "}
									</p>
								</div>
								{/* Submission */}
								<div className="form-group">
									<button className="btn btn-primary mt-3" type="submit">
										Submit Referral
									</button>
								</div>
							</form>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export const query = graphql`
	query {
		site {
			siteMetadata {
				siteUrl
			}
		}
		socialImage: file(relativePath: { eq: "hero-home-comp.jpg" }) {
			publicURL
		}
		pageData: wpPage(slug: { eq: "referral" }) {
			seoFields {
				title
				metaDescription
				opengraphTitle
				opengraphDescription
				localBusinessSchema
				image {
					node {
						sourceUrl
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									formats: [AUTO, WEBP]
									quality: 100
									transformOptions: { cropFocus: CENTER, fit: CONTAIN }
									layout: CONSTRAINED
									placeholder: BLURRED
								)
								original {
									width
									height
								}
							}
						}
					}
				}
			}
			referralPageField {
				referralIntoSection {
					description
					heading
				}
			}
		}
	}
`;

export default ReferralPage;
